import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { Form } from 'react-bootstrap';
import DragDropSelect from "../../common/DragDropSelect";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import bgProfile from "../../images/profile-placeholder.png";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const Banner = () => {

    const [selectedTab, setSelectedTab] = React.useState("1");
    const [imageAndAltWithTabs, setImageAndAltWithTabs] = useState({});

    const [submitBtn, setSubmitBtn] = useState({
        text: "Save",
        disabled: false
    });

    const [formData, setFormData] = useState({
        banner_image: "",
        banner_image_alt: "",
        banner_link: { title: "", url: "" },
        banner_sec_img: "",
        gif_image1: "",
        gif_image1_alt: "",
        gif_image2: "",
        gif_image2_alt: "",
        isActive: true,
        stripImage: "",
        color: "",
        isPoper: true,
    });


    const [secondaryImages, setSecondaryImages] = useState([]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.includes("banner_link")) {
            const key = name.split(".")[1];
            setFormData((prev) => ({
                ...prev,
                banner_link: { ...prev.banner_link, [key]: value },
            }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        if (file) {
            // Check if the file is a valid image (in this case, GIF)
            if (file.type.startsWith('image')) {
                // Create a preview URL for the image
                const previewUrl = URL.createObjectURL(file);

                // Update the formData state with the file and preview URL
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: file,
                    [`${name}Preview`]: previewUrl,
                }));
            }
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSubmit = new FormData();

        // Append fields for banner_link
        formDataToSubmit.append("banner_link[title]", formData.banner_link.title);
        formDataToSubmit.append("banner_link[url]", formData.banner_link.url);

        formDataToSubmit.append("banner_image_alt", formData.banner_image_alt);
        formDataToSubmit.append("banner_sec_img", formData.banner_sec_img);
        formDataToSubmit.append("gif_image1", formData.gif_image1);
        formDataToSubmit.append("gif_image1_alt", formData.gif_image1_alt);
        formDataToSubmit.append("gif_image2", formData.gif_image2);
        formDataToSubmit.append("gif_image2_alt", formData.gif_image2_alt);
        formDataToSubmit.append("isActive", formData.isActive);
        formDataToSubmit.append("stripImage", formData.stripImage);
        formDataToSubmit.append("color", formData.color);
        formDataToSubmit.append("isPoper", formData.isPoper);

        // Handle file uploads for images if selected
        if (formData.banner_image) {
            formDataToSubmit.append("banner_image", formData.banner_image);
        }

        const cookie = new Cookies();
        const token = cookie.get("authToken");
        try {
            const response = await axios.post(`${process.env.REACT_APP_CREATE_BANNER}`, formDataToSubmit, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                }
            });
            if (response.data.status === 1) {
                toast.success("Success!");
            } else {
                toast.error(response.data.message || "Something went wrong!");
            }
        } catch (error) {
            console.error("Error submitting the banner:", error);
            alert("Failed to submit the banner.");
        }
    };




    const [activeTabs, setActiveTabs] = useState("");
    const addImageInputWithTabs = () => {
        setImageAndAltWithTabs(prevState => ({
            ...prevState,
            [activeTabs]: {
                ...prevState[activeTabs],
                imageData: [
                    ...prevState[activeTabs].imageData,
                    { image: null, alt: "" }
                ]
            }
        }));
    };

    const handleThumbnail = (e, type) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result;

                // Ensure the correct property is updated based on the 'type' parameter
                if (type === 'first') {
                    setFormData((oldData) => ({
                        ...oldData,
                        gif_image1: base64String, // store the base64 image data
                        gif_image1Preview: URL.createObjectURL(file), // Preview URL
                    }));
                } else if (type === 'second') {
                    setFormData((oldData) => ({
                        ...oldData,
                        gif_image2: base64String, // store the base64 image data
                        gif_image2Preview: URL.createObjectURL(file), // Preview URL
                    }));
                }
            };

            reader.readAsDataURL(file);
        } else {
            // Reset if no file is selected
            if (type === 'first') {
                setFormData((oldData) => ({
                    ...oldData,
                    gif_image1: "",
                    gif_image1Preview: "",
                }));
            } else if (type === 'second') {
                setFormData((oldData) => ({
                    ...oldData,
                    gif_image2: "",
                    gif_image2Preview: "",
                }));
            }
        }
    };


    const deleteLogo = (type) => {
        if (submitBtn.disabled) {
            return;
        }

        setFormData((oldData) => {
            const updatedData = { ...oldData };

            if (type === 'first') {
                updatedData.banner_image = "";
                updatedData.banner_image_preview = "";
            } else if (type === 'second') {
                updatedData.banner_sec_img = "";
                updatedData.banner_sec_img_preview = "";
            } else if (type === 'strip') {
                updatedData.strip_gif = "";
                updatedData.strip_gif_preview = "";
            }

            return updatedData;
        });
    };


    const deleteLogoStrip = (type) => {
        if (submitBtn.disabled) {
            return;
        }

        setFormData((oldData) => {
            const updatedData = { ...oldData };

            if (type === 'first') {
                updatedData.banner_image = "";
                updatedData.banner_image_preview = "";
            } else if (type === 'second') {
                updatedData.banner_sec_img = "";
                updatedData.banner_sec_img_preview = "";
            } else if (type === 'strip') {
                updatedData.strip_gif = "";
                updatedData.strip_gif_preview = "";
            }

            return updatedData;
        });
    };



    const handleBannerUpload = (e, type) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result;

                // Handle the first banner image
                if (type === 'first') {
                    setFormData((oldData) => ({
                        ...oldData,
                        banner_image: base64String,
                        banner_image_preview: URL.createObjectURL(file), // For preview
                    }));
                }
                // Handle the second banner image
                else if (type === 'second') {
                    setFormData((oldData) => ({
                        ...oldData,
                        banner_sec_img: base64String,
                        banner_sec_img_preview: URL.createObjectURL(file), // For preview
                    }));
                }
            };

            reader.readAsDataURL(file);
        } else {
            // Reset if no file is selected
            if (type === 'first') {
                setFormData((oldData) => ({
                    ...oldData,
                    banner_image: "",
                    banner_image_preview: "",
                }));
            } else if (type === 'second') {
                setFormData((oldData) => ({
                    ...oldData,
                    banner_sec_img: "",
                    banner_sec_img_preview: "",
                }));
            }
        }
    };

    const handleStripUpload = (e, type) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result;

                // Handle the first banner image
                if (type === 'first') {
                    setFormData((oldData) => ({
                        ...oldData,
                        stripImage: base64String,
                        stripImage_preview: URL.createObjectURL(file), // For preview
                    }));
                }
                // Handle the second banner image
                else if (type === 'second') {
                    setFormData((oldData) => ({
                        ...oldData,
                        banner_sec_img: base64String,
                        stripImage_preview: URL.createObjectURL(file), // For preview
                    }));
                }
            };

            reader.readAsDataURL(file);
        } else {
            // Reset if no file is selected
            if (type === 'first') {
                setFormData((oldData) => ({
                    ...oldData,
                    stripImage: "",
                    stripImage_preview: "",
                }));
            } else if (type === 'second') {
                setFormData((oldData) => ({
                    ...oldData,
                    banner_sec_img: "",
                    banner_sec_img_preview: "",
                }));
            }
        }
    };


    // Function to delete GIF
    const deleteGif = (gifKey) => {
        if (gifKey === 'gif_image1') {
            setFormData((prevData) => ({
                ...prevData,
                gif_image1: null,
                gif_image1Preview: null,
            }));
        } else if (gifKey === 'gif_image2') {
            setFormData((prevData) => ({
                ...prevData,
                gif_image2: null,
                gif_image2Preview: null,
            }));
        }
    };
    

    // Function to delete the Strip Image
const deleteStripImage = () => {
    setFormData((prevData) => ({
      ...prevData,
      stripImage: null,
    }));
  };

    return (
        <div>
            <ToastContainer />
            <div className="admin-topbar">
                <h5 className="Main-heading">Create Banner</h5>
            </div>
            <Row>
                <Col sm="12" lg="9" xl="9" className="blog-right-side">
                    <div className="card card-body blog-details">
                        <Box sx={{ width: "100%", typography: "body1" }}>
                            <TabContext value={selectedTab}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        onChange={(event, newValue) => setSelectedTab(newValue)}
                                    >
                                        <Tab label="General" value="1" />
                                        <Tab label="Add GIF And Alt Tags" value="2" />
                                        <Tab label="Add Strip GIF And Strip Color" value="3" />
                                    </TabList>
                                </Box>
                                <form onSubmit={handleSubmit}>
                                    <div className="tab-content">
                                        <TabPanel value="1">
                                            <div className="form-group">
                                                <label className="card-title">
                                                    Banner Name
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="(ex: Banner Name)"
                                                    name="banner_name"
                                                    value={formData.banner_link.title}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            banner_link: {
                                                                ...formData.banner_link,
                                                                title: e.target.value,
                                                            },
                                                        })
                                                    }
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label className="card-title">
                                                    URL
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="(ex: https://example.com)"
                                                    name="banner_link.url"
                                                    value={formData.banner_link.url}
                                                    onChange={handleInputChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label className="card-title">
                                                    Status
                                                </label>
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    name="isActive"
                                                    value={formData.isActive}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            isActive: e.target.value === "true",
                                                        })
                                                    }
                                                >
                                                    <option value={true}>Published</option>
                                                    <option value={false}>Draft</option>
                                                </Form.Select>
                                            </div>


                                            {/* Banner First Image */}
                                            <div className="form-group">
                                                <h5 className="card-title">First Banner Image </h5>
                                                <div className="selct-profile-picture">
                                                    <div className="company-logo-selection-img">
                                                        {formData.banner_image_preview ? (
                                                            <img src={formData.banner_image_preview} width="100%" alt="Banner Image 1 Preview" />
                                                        ) : (
                                                            <img src={bgProfile} width="100%" alt="Default Image" />
                                                        )}
                                                    </div>
                                                    {formData.banner_image ? (
                                                        <div className="company-logo-selection-file" onClick={() => deleteLogo('first')}>
                                                            <label className="cstm-btn delete-logo"><i className="far fa-image"></i> Delete Photo</label>
                                                        </div>
                                                    ) : (
                                                        <div className="company-logo-selection-file">
                                                            <input
                                                                id="choose-logo-first"
                                                                className="cstm-form company"
                                                                type="file"
                                                                accept="image/*"
                                                                name="banner_image"
                                                                onChange={(e) => handleBannerUpload(e, 'first')}
                                                                disabled={submitBtn.disabled}
                                                            />
                                                            <label htmlFor="choose-logo-first" className="cstm-btn"><i className="far fa-image"></i> Upload Photo</label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            {/* Banner Second Image */}
                                            <div className="form-group">
                                                <h5 className="card-title">Animation Image </h5>
                                                <div className="selct-profile-picture">
                                                    <div className="company-logo-selection-img">
                                                        {formData.banner_sec_img_preview ? (
                                                            <img src={formData.banner_sec_img_preview} width="100%" alt="Banner Image 2 Preview" />
                                                        ) : (
                                                            <img src={bgProfile} width="100%" alt="Default Image" />
                                                        )}
                                                    </div>
                                                    {formData.banner_sec_img ? (
                                                        <div className="company-logo-selection-file" onClick={() => deleteLogo('second')}>
                                                            <label className="cstm-btn delete-logo"><i className="far fa-image"></i> Delete Photo</label>
                                                        </div>
                                                    ) : (
                                                        <div className="company-logo-selection-file">
                                                            <input
                                                                id="choose-logo-second"
                                                                className="cstm-form company"
                                                                type="file"
                                                                accept="image/*"
                                                                name="banner_sec_img"
                                                                onChange={(e) => handleBannerUpload(e, 'second')}
                                                                disabled={submitBtn.disabled}
                                                            />
                                                            <label htmlFor="choose-logo-second" className="cstm-btn"><i className="far fa-image"></i> Upload Photo</label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>


                                        </TabPanel>

                                        <TabPanel value="2">
                                            <div className="create-another-img-list">

                                                {/* GIF Image 1 */}
                                                <div className="form-group">
                                                    <h5 className="card-title">GIF Image 1 </h5>
                                                    <div className="selct-profile-picture">
                                                        <div className="company-logo-selection-img">
                                                            {formData.gif_image1Preview ? (
                                                                <img src={formData.gif_image1Preview} width="100%" alt="GIF Image 1 Preview" />
                                                            ) : (
                                                                <img src={bgProfile} width="100%" alt="Default GIF" />
                                                            )}
                                                        </div>
                                                        {formData.gif_image1 ? (
                                                            <div className="company-logo-selection-file" onClick={() => deleteGif('gif_image1')}>
                                                                <label htmlFor="choose-gif1" className="cstm-btn delete-logo">
                                                                    <i className="far fa-image"></i> Delete GIF
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            <div className="company-logo-selection-file">
                                                                <input
                                                                    id="choose-gif1"
                                                                    className="cstm-form company"
                                                                    type="file"
                                                                    accept="image/*"
                                                                    name="gif_image1"
                                                                    onChange={(e) => handleThumbnail(e, 'first')}
                                                                    disabled={submitBtn.disabled}
                                                                />
                                                                <label htmlFor="choose-gif1" className="cstm-btn">
                                                                    <i className="far fa-image"></i> Upload GIF
                                                                </label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Alt Text for GIF Image 1 */}
                                                <div className="form-group">
                                                    <label className="card-title">Alt Text for GIF Image 1 </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter alt text for GIF Image 2"
                                                        name="gif_image1_alt"
                                                        value={formData.gif_image1_alt}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>

                                                {/* GIF Image 2 */}
                                                <div className="form-group">
                                                    <h5 className="card-title">GIF Image 2 </h5>
                                                    <div className="selct-profile-picture">
                                                        <div className="company-logo-selection-img">
                                                            {formData.gif_image2Preview ? (
                                                                <img src={formData.gif_image2Preview} width="100%" alt="GIF Image 2 Preview" />
                                                            ) : (
                                                                <img src={bgProfile} width="100%" alt="Default GIF" />
                                                            )}
                                                        </div>
                                                        {formData.gif_image2 ? (
                                                            <div className="company-logo-selection-file" onClick={() => deleteGif('gif_image2')}>
                                                                <label htmlFor="choose-gif2" className="cstm-btn delete-logo">
                                                                    <i className="far fa-image"></i> Delete GIF
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            <div className="company-logo-selection-file">
                                                                <input
                                                                    id="choose-gif2"
                                                                    className="cstm-form company"
                                                                    type="file"
                                                                    accept="image/*"
                                                                    name="gif_image2"
                                                                    onChange={(e) => handleThumbnail(e, 'second')}
                                                                    disabled={submitBtn.disabled}
                                                                />
                                                                <label htmlFor="choose-gif2" className="cstm-btn">
                                                                    <i className="far fa-image"></i> Upload GIF
                                                                </label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>



                                                {/* Alt Text for GIF Image 2 */}
                                                <div className="form-group">
                                                    <label className="card-title">Alt Text for GIF Image 2 </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter alt text for GIF Image 2"
                                                        name="gif_image2_alt"
                                                        value={formData.gif_image2_alt}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>

                                            </div>
                                        </TabPanel>

                                        <TabPanel value="3">
                                            {/* Banner Color */}
                                            <div className="form-group">
                                                <label className="card-title">
                                                    Color
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Banner Color"
                                                    name="color"
                                                    value={formData.color}
                                                    onChange={handleInputChange}
                                                />

                                            </div>

                                            {/* Strip GIF */}
                                            <div className="form-group">
                                                <h5 className="card-title">Strip Image </h5>
                                                <div className="selct-profile-picture">
                                                    <div className="company-logo-selection-img">
                                                        {formData.stripImage ? (
                                                            <img src={formData.stripImage} width="100%" alt="stripImage Image 1 Preview" />
                                                        ) : (
                                                            <img src={bgProfile} width="100%" alt="Default Image" />
                                                        )}
                                                    </div>
                                                    {formData.stripImage ? (
                                                        <div className="company-logo-selection-file" onClick={deleteStripImage}>
                                                            <label className="cstm-btn delete-logo"><i className="far fa-image"></i> Delete Photo</label>
                                                        </div>
                                                    ) : (
                                                        <div className="company-logo-selection-file">
                                                            <input
                                                                id="choose-logo-first"
                                                                className="cstm-form company"
                                                                type="file"
                                                                accept="image/*"
                                                                name="stripImage"
                                                                onChange={(e) => handleStripUpload(e, 'first')}
                                                                disabled={submitBtn.disabled}
                                                            />
                                                            <label htmlFor="choose-logo-first" className="cstm-btn"><i className="far fa-image"></i> Upload Photo</label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="card-title">
                                                    Popper
                                                </label>
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    name="isPoper"
                                                    value={formData.isPoper}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            isPoper: e.target.value === "true",
                                                        });
                                                    }}
                                                >
                                                    <option value={true}>True</option>
                                                    <option value={false}>False</option>
                                                </Form.Select>
                                            </div>
                                        </TabPanel>

                                        <div className="submit-buttons">
                                            <button className="save-btn" type="submit">{submitBtn.text}</button>
                                        </div>
                                    </div>
                                </form>
                            </TabContext>
                        </Box>
                    </div>
                </Col>
            </Row>

        </div>
    );
};

export default Banner;
