import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const animatedComponents = makeAnimated();

const DragDropSelect = ({ options, value, onChange }) => {
  // console.log(value,"valueueueue")
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(value);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    onChange(reorderedItems);
  };

  return (
    <div>
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        options={options}
        value={value}
        onChange={onChange}
      />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {
          value && value.length > 0 &&
          <h5 className='mb-3 mt-3'>Drag and Drop</h5>
        }
        <Droppable droppableId="selected-options" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {value && value.map((option, index) => (
                <Draggable key={option.value} draggableId={option.value} index={index}>
                  {(provided) => (
                      <>
                        
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        padding: 8,
                        margin: '0 0 8px 0',
                        background: '#fff',
                        border: '1px solid lightgrey',
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'space-between',
                        ...provided.draggableProps.style,
                      }}
                    >
                      {option.label}
                    </div>
                      </>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DragDropSelect;
