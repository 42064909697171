import LoginReducer from "./components/Login/Login.reducer";
import { createStore, applyMiddleware, combineReducers } from "redux";
import logger from "redux-logger";
import {thunk} from 'redux-thunk';

const middlewares = applyMiddleware(
  logger,
  thunk
);
const root = combineReducers({
  LoginReducer,
});
const storage = createStore(root,{},middlewares);
export default storage;
