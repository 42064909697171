import {
    Row,
    Col,
    Button,
    Spinner
} from "reactstrap";
import React, { useEffect } from "react";
import { useState, useRef } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import DataTable from 'datatables.net-dt';
import $ from 'jquery';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2';




const BannerLists = () => {

    const [bannerResponse, setBannerResponse] = useState([]);
    const [waitResponse, setWaitResponse] = useState(true);
    const cookie = new Cookies();
    const navigate = useNavigate();
    const tableRef = useRef();
    const token = cookie.get("authToken");

    const list_Banner = async () => {
        try {
            const response = await axios({
                method: "get",
                url: `${process.env.REACT_APP_BANNER}`,
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token,
                },
            });

            console.log(response.data, 'response.data');
            setBannerResponse(response.data);
            setWaitResponse(false);
        } catch (err) {
            console.error("Error fetching data:", err);
            setWaitResponse(false);
        }
    };


    useEffect(() => {
        list_Banner();
    }, []);


    return (
        <div>
            <ToastContainer />
            <div className="admin-topbar">
                <h5 className="Main-heading">Home Page Banner</h5>
                <div className="submit-buttons">
                    <Button
                        color="primary"
                        onClick={() => navigate("/banner")}
                    >
                        + Add Banner
                    </Button>
                </div>
            </div>

            {waitResponse ? (
                <div className="loading">
                    <Spinner color="primary" />
                </div>
            ) : (
                <Row>
                    <Col sm="12" lg="12" xl="12" className="blog-listing">
                        <div className="card card-body blog-details">
                            <div className="blog-list">
                                <table ref={tableRef} id="myTable" className="table">
                                    <thead>
                                        <tr>
                                            <th>Banner Title</th>
                                            <th>Status</th>
                                            <th>Poper</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bannerResponse.length > 0 && bannerResponse.map((data, index) => (
                                            <tr key={data._id}>
                                                <td>
                                                    <div className="blog-list-table">
                                                        <div className="blog-list-title">
                                                            <h4>{data?.banner_link.title}</h4>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="badge" style={{ textTransform: "capitalize" }}>
                                                        <select
                                                            className={`${data?.[index]
                                                                ? data?.[index] === "published"
                                                                    ? 'badge-success'
                                                                    : 'badge-danger'
                                                                : data?.isActive
                                                                    ? 'badge-success'
                                                                    : 'badge-danger'
                                                                }`}
                                                            value={(data?.isActive ? "published" : "draft")}
                                                        >
                                                            <option value="draft">Draft</option>
                                                            <option value="published">Published</option>
                                                        </select>
                                                    </div></td>
                                                <td>
                                                    <div class="badge" style={{ textTransform: "capitalize" }}>
                                                        <select
                                                            className={
                                                                data?.[index] === "true" || data?.isPoper === true
                                                                    ? "badge-success"
                                                                    : "badge-danger"
                                                            }
                                                            value={data?.isPoper ? "true" : "false"}
                                                        >
                                                            <option value="true">True</option>
                                                            <option value="false">False</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ul className="action-btns">
                                                        <Tooltip title="Edit">
                                                            <li className="edit-icon" ><span><i class="fas fa-pencil-alt"></i></span></li>
                                                        </Tooltip>
                                                        {
                                                            data.key !== "not_in_the_list" && (
                                                                <Tooltip title="Delete">
                                                                    <li
                                                                        className="delete-icon"

                                                                    >
                                                                        <span>
                                                                            <i className="fas fa-trash-alt"></i>
                                                                        </span>
                                                                    </li>
                                                                </Tooltip>
                                                            )
                                                        }
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default BannerLists;
