const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const USER_NOT_FOUND = "USER_NOT_FOUND";
const INCORRECT_PASSWORD = "INCORRECT_PASSWORD";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const LOGOUT_FAILED = "LOGOUT_FAILED";
export {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  USER_NOT_FOUND,
  INCORRECT_PASSWORD,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED
}
