import {
  Row,
  Col,
  Button,
  Spinner
} from "reactstrap";
import React, { useEffect } from "react";
import { useState, useRef } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from 'datatables.net-dt';
import $ from 'jquery';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2';




const ListSelectOccasion = () => {

  const [blogResponse, setBlogResponse] = useState("");
  const [tableRun, setTableRun] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [waitResponse, setWaitResponse] = useState(true);

  const { selectTypeId } = useParams();
  const [events, setEvents] = useState([]);
  const [positions, setPositions] = useState({});

  useEffect(() => {
    fetchEvents();
  }, []);
  const handlePositionChange = (eventId, newPosition) => {
    const position = Math.max(Number(newPosition) || 0, 0);
  
    setPositions((prevPositions) => ({
      ...prevPositions,
      [eventId]: position,
    }));
  
    updatePositionOnServer(eventId, position);
  };
  
  const fetchEvents = async () => {
    try {
      const token = cookie.get("authToken");
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SELECT_OCCASION}/${selectTypeId}`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      });
      if (response.data.status === 1) {
        const eventPositions = {};
        response.data.data.forEach((event) => {
          eventPositions[event._id] = event.position;
        });
        console.log("Fetched positions:", eventPositions); 
        setEvents(response.data.data);
        setPositions(eventPositions);
      }
    } catch (err) {
      console.error("Error fetching events", err);
    }
  };

  const updatePositionOnServer = async (eventId, newPosition) => {
    try {
      const token = cookie.get("authToken");
      const response = await axios.put(
        `${process.env.REACT_APP_SELECT_OCCASION}/${eventId}`,
        { position: newPosition }, 
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
      console.log(`Position update response for ${eventId}:`, response.data);
      if (response.data.status === 1) {
        toast.success("Position updated successfully!");
      } else {
        toast.error(`Failed to update position: ${response.data.message}`);
      }
    } catch (err) {
      console.error("Error updating position:", err);
      toast.error(`An error occurred: ${err.message}`);
    }
  };

  const incrementPosition = (eventId) => {
    setPositions((prevPositions) => {
      const currentPosition = prevPositions[eventId] || 0;
      const newPosition = currentPosition + 1;
      // console.log(`Incrementing position for ${eventId}: ${currentPosition} -> ${newPosition}`);
      updatePositionOnServer(eventId, newPosition);
      return {
        ...prevPositions,
        [eventId]: newPosition,
      };
    });
  };

  const decrementPosition = (eventId) => {
    setPositions((prevPositions) => {
      const currentPosition = prevPositions[eventId] || 1;
      const newPosition = Math.max(currentPosition - 1, 1); 
      updatePositionOnServer(eventId, newPosition);
      return {
        ...prevPositions,
        [eventId]: newPosition,
      };
    });
  };


  const cookie = new Cookies();
  const navigate = useNavigate();
  const tableRef = useRef();

  const initDataTable = () => {
    $(tableRef.current).DataTable().destroy(); // Destroy the DataTable instance
    $(tableRef.current).DataTable({
      responsive: true,
      order: [],
      // Additional DataTable options go here
    });
  };
  useEffect(() => {
    document.title = "Varnz.ai | post"
  }, [])
  useEffect(() => {
    if (tableRun) {
      initDataTable();
    }
  }, [tableRun, blogResponse]);

  const handleSelectChange = async (event, index, blogId) => {
    setSelectedValue((oldData) => {
      return {
        ...oldData,
        [index]: event.target.value
      }
    });
    try {
      const token = cookie.get("authToken");
      const formData = {
        status: event.target.value,
        type: "status"
      }
      const response = await axios.put(
        `${process.env.REACT_APP_SELECT_OCCASION}/${blogId}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        }
      );

      if (response.data.status === 1) {
        toast.success(response.data.message);
        window.location.reload();

      } else {
        setSelectedValue((oldData) => {
          return {
            ...oldData,
            [index]: ""
          }
        });
        toast.error(response.data.message)
      }

    } catch (err) {

    }
  };

  useEffect(() => {
    document.title = "Varnz.ai | post"
  }, [])

  const list_blog = async () => {
    try {
      const token = cookie.get("authToken");
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_SELECT_OCCASION,
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        },
      });
      if (response.data.status === 1) {
        setBlogResponse((response.data.data));
        setTableRun(true);
        setWaitResponse(false);
      } else {

      }
    } catch (err) {

    }
  }

  const deleteBlog = async (blogId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = cookie.get("authToken");
          const response = await axios.delete(`${process.env.REACT_APP_SELECT_OCCASION}/${blogId}`, {
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': token
            },
          });
          if (response.data.status === 1) {
            toast.success("Deleted Successful!")
            setBlogResponse(blogResponse.filter((blogItem) => blogItem._id !== blogId));
            window.location.reload();
          } else {
            toast.error(response.data.message)
          }
        } catch (err) {

        }
      }
    });

  }

  const editBlog = (id) => {
    return navigate(`/update-select-occasion/${id}`);
  }


  useEffect(() => {
    list_blog();
  }, []);

  return (
    <div>
      <ToastContainer />
      <div className="admin-topbar">
        <h5 className="Main-heading">Occasion List</h5>
        <div className="submit-buttons">
          <Button
            color="primary"
            onClick={() => {
              return navigate("/create-select-occasion")
            }}
          >
            + Add Occasion
          </Button>
        </div>
      </div>
      {
        true &&
        <Row>
          <Col sm="12" lg="12" xl="12" className="blog-listing">
            <div className="card card-body blog-details">
              <div className="blog-list">
                <table ref={tableRef} id="myTable" className="table">
                  <thead>
                    <tr>
                      <th>Display Name</th>
                      <th>Value</th>
                      <th>Key</th>
                      <th>Position</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      blogResponse && blogResponse.map((data, index) => {
                        return <tr>
                          <td>
                            <div className="blog-list-table">
                              <div className="blog-list-title">
                                <h4>{data?.label}</h4>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="blog-list-table">
                              <div className="blog-list-title">
                                <h4>{data?.value}</h4>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="blog-list-table">
                              <div className="blog-list-title">
                                <h4>{data?.key}</h4>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="blog-list-table">
                              <div className="blog-list-title position-controls">
                              <input
                                type="number"
                                value={positions[data._id] !== undefined ? positions[data._id] : data?.position}
                                onChange={(e) => handlePositionChange(data._id, e.target.value)}
                                className="position-input"
                                min="0" /* Prevents negative numbers */
                              />
                                {/* <h4>{positions[data._id] !== undefined ? positions[data._id] : data?.position}</h4> */}
                                <button onClick={() => incrementPosition(data._id)}>+</button>
                                <button onClick={() => decrementPosition(data._id)}>-</button>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="badge" style={{ textTransform: "capitalize" }}>
                              <select
                                className={`${selectedValue?.[index] ? selectedValue?.[index] === "published" ? 'badge-success' : 'badge-danger' : data?.isActive ? 'badge-success' : 'badge-danger'}`}
                                value={selectedValue?.[index] || (data?.isActive ? "published" : "draft")}
                                onChange={(e) => handleSelectChange(e, index, data._id)}>
                                <option value="draft">Draft</option>
                                <option value="published">Published</option>
                              </select>
                            </div></td>
                          <td>

                            <ul className="action-btns">
                              <Tooltip title="Edit">
                                <li className="edit-icon" onClick={() => { editBlog(data._id) }}><span><i className="fas fa-pencil-alt"></i></span></li>
                              </Tooltip>
                              <Tooltip title="Delete" >
                                <li className="delete-icon" onClick={() => { deleteBlog(data._id) }}><span><i className="fas fa-trash-alt"></i></span></li>
                              </Tooltip>
                            </ul>
                          </td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div>

            </div>
          </Col>
        </Row>
      }


    </div>
  );
};

export default ListSelectOccasion;
